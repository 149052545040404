@font-face {
    font-family: "font-arab";
    src: url('../fonts/LPMQ_IsepMisbah.ttf') format('truetype');
    /*  */
    /* Atau gunakan path yang sesuai dengan lokasi font Anda */
}

.arab {
    font-size: 25px;
    line-height: 2.5em;
    font-style: normal;
    font-weight: 400;
    text-align: right;
}

.number {
    margin: 14px 0 0 0;
    width: 41px;
    height: 48px;
    background-image: url(../images/frame-number.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    background-color: whitesmoke;
    padding: 10;
    border: '1px solid #d9d9d9';
    border-radius: '8px';
}

.yasin {
    background-color: whitesmoke;
}

.ant-space-item:nth-child(2) {
    width: 100%;
}