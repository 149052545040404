@font-face {
    font-family: "font-head";
    src: url('../fonts/LucidaCalligraphy.ttf') format('truetype');
    /*  */
    /* Atau gunakan path yang sesuai dengan lokasi font Anda */
}

.fhead{
    padding: 0;
}

.fhead p{
    font-family: 'font-head';
    font-size: 14px;

}

.head {
    font-size: 20px !important;
    line-height: 2em;
    font-style: bold;
    font-weight: 1000;
    text-align: center;
    margin: 0;
}

.head2 {
    margin: 2em 0;
}
.head3 {
    text-align: center;
    background-color: #ffffff00 !important; 
}
.head4 p {
    text-align: center;
    line-height: 2em;
    margin: 2em 0;
}
.head5{
    margin-bottom: 2em;
}
.head5 p {
    text-align: center;
    line-height: 2em;
    margin: 0;
}

.head2 p{
    text-align: center;
    line-height: 0.75em;
    font-size: 16px;
}

.head3 {
    background-color: whitesmoke;
    padding: 10;
    border: '1px solid #d9d9d9';
    border-radius: '8px';
}

.popup {
    margin: 1.5em 0;
}

.popup p{
    margin: 0;
}